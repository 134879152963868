import { Box, Typography } from '@mui/material';
import React from 'react';
import Seyarkai_logo from '../comp/images/Seyarkai-logo.png';
import { Link as ScrollLink } from 'react-scroll';
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import FooterBox from './FooterBox';
import Link from '@mui/material/Link';

const pages = [
  { text: 'About', to: 'About' },
  { text: 'Services', to: 'Services' },
  { text: 'Industries We Serve', to: 'Industries_We_Serve' },
  { text: 'Our Vision', to: 'Our_Mission' },
  { text: 'Why Us', to: "Why_Us" },
  // { text: 'Testimonial', to: "Testimonial" },
  { text: 'Contact Us', to: "Contact_Us" },
];

const getOffset = () => {
  const width = window.innerWidth;
  if (width >= 1280) {
    return -60;
  } else if (width >= 960) {
    return -60;
  } else if (width >= 600) {
    return -120;
  } else {
    return -100;
  }
};

const Footer = () => {
  return (
    <Box sx={{ backgroundColor: '#EEF4FB', paddingBottom: 3, filter: 'saturate(2)', borderTopLeftRadius: '50px', borderTopRightRadius: '50px' }}>
      <Box sx={{
        marginTop: { lg: 4, md: 7, sm: 5, xs: 5 },
        marginRight: { lg: 8, md: 9, sm: 10, xs: 2.7 },
        marginLeft: { lg: 14, md: 10, sm: 10, xs: 3.8 },
        marginBottom: { lg: 6 },
        display: { lg: 'flex', md: 'block', xs: 'block' },
        gap: { lg: 5, md: 3 },
        justifyContent: 'space-between',
        paddingTop: 3
      }}>
        <Box sx={{ display: { lg: 'flex', md: 'flex', sm: 'flex', xs: 'block' }, gap: { lg: 4, md: 5, sm: 6 }, flex: { lg: 0.55, md: 0.5 } }}>
          <Box sx={{ display: 'flex', flexDirection: { lg: 'column', md: 'column', sm: 'column', xs: 'column' }, maxWidth: { lg: "40%", md: '40%', sm: "45%", xs: '100%' } }}>
            <Typography>
              <img src={Seyarkai_logo} alt='Seyarkai-logo' loading='lazy' style={{ width: { lg: "35%", md: '25%' } }} />
            </Typography>
            <Typography sx={{ fontSize: { lg: 15, md: 15, sm: 15, xs: 15 }, paddingTop: { lg: 0.5, md: 1, sm: 1, xs: 0.5 }, fontFamily: 'Urbanist' }}>
              "We enhance lives using AI technology, crafting solutions to streamline processes, boost efficiency, and bring innovation that makes a difference."
            </Typography>
          </Box>

          <Box sx={{ marginLeft: { lg: 7, md: 9.5, sm: 5, xs: 0 } }}>
            <Typography sx={{ fontSize: { lg: 13, md: 12, sm: 13, xs: 15 }, color: 'rgb(179, 179, 179)', fontFamily: 'Urbanist', paddingTop: { lg: 0, md: 0.5, sm: 1, xs: 2 }, }}>
              Navigation
            </Typography>
            <Box sx={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', maxHeight: { lg: 130, md: 80, sm: 130, xs: 100 }, gap: { lg: 1, md: 1.7, sm: 1, xs: 0 }, paddingTop: { lg: 2.5, md: 3, sm: 2.5, xs: 1 } }}>
              {pages.map((page) => (
                <Typography key={page.text} sx={{ fontFamily: 'Urbanist', fontSize: { lg: 14, md: 13, sm: 14, xs: 15 }, cursor: 'pointer', paddingRight: { lg: 4, md: -1, sm: 2, }, '&:hover': { fontSize: { lg: 14 }, paddingRight: { lg: 3.85, md: 0 }, fontWeight: '500', color: 'blue', textDecoration: 'underline', transition: 'text-decoration-color 3s ease', textDecorationColor: 'blue', } }}>
                  <ScrollLink to={page.to} smooth={true} duration={1000} offset={getOffset()}>{page.text}</ScrollLink>
                </Typography>
              ))}
            </Box>
          </Box>
        </Box>

        <Box sx={{display:{lg:'flex',md:'flex',sm:'flex',xs:'block'},flex:{lg:0.45,md:0.5},marginTop:{lg:0,md:3,sm:3,xs:3},marginLeft:{lg:4}}}>
            <Box sx={{maxWidth:{lg:"40%",},display:'block',flex:{lg:0.5,md:1,sm:1}}}>
                <Typography sx={{fontSize:{lg:13,md:12,sm:13,xs:15},color:'rgb(179, 179, 179)',fontFamily:'Urbanist'}} >Contact us</Typography>
             
                    <Box sx={{ display:'flex',flexDirection:'row',flexWrap:'wrap',paddingBottom:{lg:1,md:0.5,sm:1,xs:0.5},maxHeight:{lg:110},gap:{lg:1.2},paddingTop:{lg:2.5,md:1,sm:1,xs:0.7},}}>
                        <Typography sx={{fontFamily:"Urbanist",color:'#5A5A5A',fontSize:{lg:15,md:15,sm:16,xs:16},paddingBottom:{lg:0,md:1,sm:0},paddingRight:{lg:3,md:3,sm:4,xs:3}}}>+91 93636 07947 </Typography>
                        <Typography sx={{fontFamily:"Urbanist",color:'#5A5A5A',fontSize:{lg:15,md:15,sm:16,xs:16},paddingBottom:{lg:0,md:1,sm:0},paddingRight:{lg:3,md:3,sm:4,xs:3},cursor:'pointer'}}>contactus@seyarkai.tech</Typography>
                    </Box>
            </Box>
            <Box sx={{flex:{lg:0.5,md:1,sm:2},marginLeft:{lg:8,md:8,sm:6,xs:0},maxWidth:{lg:"30%",sm:'100%'}}}>
            <Typography sx={{fontSize:{lg:13,md:12,sm:13,xs:15},color:'rgb(179, 179, 179)',fontFamily:'Urbanist',marginTop:{lg:0,md:0,sm:0,xs:2.5}}} >Follow us</Typography>
            
            <Box sx={{marginTop:{lg:2,md:2,sm:2,xs:1},display:'flex',gap:{lg:1.5,md:2,sm:2,xs:2},flexWrap:'wrap'}}>
            <Link href='https://www.facebook.com/profile.php?id=61556180181176&mibextid=ZbWKwL' target="_blank" rel="noopener noreferrer">
            <FacebookIcon  sx={{backgroundColor:'white',color:'rgb(141, 141, 141)',fontSize:{lg:'2.5vw',md:'3vw',sm:'4.5vw',xs:'9vw'},padding:{lg:1.1,md:0.9,sm:1,xs:1},borderRadius:'20px',border:'0.5px solid rgb(179, 179, 179)',cursor:'pointer','&:hover': {color:'white',border:'none',backgroundColor:'#4267B2'},transition: 'color 0.3s ease'}} />
            </Link>
            <Link href='https://www.youtube.com/@SeyarkAI' target="_blank" rel="noopener noreferrer">
                    <YouTubeIcon  sx={{backgroundColor:'white',color:'rgb(141, 141, 141)',fontSize:{lg:'2.5vw',md:'3vw',sm:'4.5vw',xs:'9vw'},padding:{lg:1.1,md:0.9,sm:1,xs:1},borderRadius:'20px',border:'0.5px solid rgb(179, 179, 179)',cursor:'pointer','&:hover': {color:'white',border:'none',backgroundColor:'red'},transition: 'color 0.3s ease'}} />
            </Link>
            <Link href='https://www.linkedin.com/company/seyarkai/' target="_blank" rel="noopener noreferrer">
                    <LinkedInIcon  sx={{backgroundColor:'white',color:'rgb(141, 141, 141)',fontSize:{lg:'2.5vw',md:'3vw',sm:'4.5vw',xs:'9vw'},padding:{lg:1.1,md:0.9,sm:1,xs:1},borderRadius:'20px',border:'0.5px solid rgb(179, 179, 179)',cursor:'pointer','&:hover': {color:'white',border:'none',backgroundColor:'#4267B2'},transition: 'color 0.3s ease'}} />
            </Link>
            <Link href='https://www.instagram.com/seyarkai.tech?igsh=MXN5am95YXJsNHVycg==' target="_blank" rel="noopener noreferrer">
                     <InstagramIcon  sx={{backgroundColor:'white',color:'rgb(141, 141, 141)',fontSize:{lg:'2.5vw',md:'3vw',sm:'4.5vw',xs:'9vw'},padding:{lg:1.1,md:0.9,sm:1,xs:1},borderRadius:'20px',border:'0.5px solid rgb(179, 179, 179)',cursor:'pointer','&:hover': {backgroundImage: 'linear-gradient(to right, #833AB4, #FD1D1D)',color:'white'},transition: 'color 0.3s ease'}} />
            </Link>
                </Box>
            </Box>
            <Box sx={{flex:{lg:0.5,md:1,sm:1},marginLeft:{lg:10,md:10,sm:6,xs:0},marginRight:{lg:0,md:-6,sm:-2 },maxWidth:{lg:"40%",}}}>
            <Typography sx={{fontSize:{lg:13,md:12,sm:13,xs:15},color:'rgb(179, 179, 179)',marginBottom:2,fontFamily:'Urbanist',marginTop:{lg:0,md:0,sm:0,xs:2.5}}} >Chat with us</Typography>
            <Link href='https://wa.me/qr/3U2CRVCFAC2MK1' sx={{borderRadius:'50px', alignItems:'center' }}>
            <WhatsAppIcon sx={{backgroundColor:'white',color:'rgb(141, 141, 141)',fontSize:{lg:'2.5vw',md:'3vw',sm:'4.5vw',xs:'9vw'},padding:{lg:1.1,md:0.9,sm:1,xs:1},borderRadius:'20px',border:'0.5px solid rgb(179, 179, 179)',cursor:'pointer','&:hover': {backgroundColor:'#128C7E',color:'white'},transition: 'color 0.3s ease'}} />

            </Link>
            </Box>
            

        </Box>
    </Box>
      <FooterBox />
    </Box>
  );
};

export default Footer;
