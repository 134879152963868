import React, { useState, useEffect } from 'react';
import { IconButton } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const ScrollToSectionButton = () => {
  const [isVisible, setIsVisible] = useState(false);

  const handleScroll = () => {
    const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

    // Show/hide the button based on the scroll position
    setIsVisible(scrollTop > 300);
  };

  const scrollToSection = () => {
    const section = document.getElementById('HeroSection');
    if (section) {
      const offset = { lg: -30, md: -30, sm: -30, xs: -20};
      const rect = section.getBoundingClientRect();
      const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
      const sectionTop = rect.top + scrollTop;
      
      // Adjust for the offset based on the current viewport width
      let offsetValue = 0;
      if (window.innerWidth >= 1280) {
        offsetValue = offset.lg;
      } else if (window.innerWidth >= 960) {
        offsetValue = offset.md;
      } else if (window.innerWidth >= 600) {
        offsetValue = offset.sm;
      } else {
        offsetValue = offset.xs;
      }
      
      window.scrollTo({
        top: sectionTop + offsetValue,
        behavior: 'smooth'
      });
    }
  };

  useEffect(() => {
    // Attach the scroll event listener
    window.addEventListener('scroll', handleScroll);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <div style={{ position: 'fixed', bottom: 16, right: 16 }}>
      {isVisible && (
        <div style={{ fontSize: {lg:50,md:50,sm:90,xs:70} }}>
          <IconButton
            onClick={scrollToSection}
            sx={{
              color: "white",
              background: 'linear-gradient(to right, #4E4BE4,#A358F2)',
              width:'2vw',
              height: '2vw',
              '&:hover': {
                backgroundColor: 'white',
                '& svg': { color: 'linear-gradient(to right, #4E4BE4,#A358F2)' }
              }
            }}
          >
            <KeyboardArrowUpIcon />
          </IconButton>
        </div>
      )}
    </div>
  );
};

export default ScrollToSectionButton;
