import { Box, Skeleton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import step_toward_automation from './images/Step-toward-automation.webp';
import faster_and_efficient from './images/faster-and-efficient.webp';
import digital_connection from './images/digital-connection.webp';
import precised_and_balanced from './images/precised-and-balanced.webp';
import whyus_background from './images/whyus-background.avif';
import AOS from 'aos';
import 'aos/dist/aos.css'; 
AOS.init();


const Whyus = () => {
     const [loading, setLoading] = useState(true);

  useEffect(() => {
    
    setTimeout(() => {
      setLoading(false);
    }, 1000); 
  }, []);
  
  return (
    <div id="Why_Us"  >
     <Box  sx={{ display: 'block' ,backgroundImage: {lg:`url(${whyus_background})`,md:`url(${whyus_background})`,sm:'none',xs:'none'}, backgroundRepeat: 'no-repeat', backgroundPosition: 'left', backgroundSize: { xs: '100% 50%',sm : '100% 60%', md: '100% 100%',lg: ' 55% 100%'},paddingBottom:5,filter: 'saturate(1.5)',marginTop: { xs:13,lg:7,md:8,sm:12}}}>
          <Box sx={{ display: 'flex', maxWidth: '100%', marginLeft: '7%', justifyContent: 'space-between', alignItems: 'center', marginRight: '2%', gap: '3%', flexDirection: { xs: 'column', md: 'row' } ,}}>
               <Box  sx={{ maxWidth: { xs: '100%',sm:'85%', md: '50%',lg:'60%' }, marginTop: -6, textAlign: 'justify',paddingRight:1.5 }}>
               <div  data-aos="fade-up"  data-aos-duration="1000"  data-aos-once="true" data-aos-anchor-placement="top-bottom">
                    <Typography  variant="h5" sx={{ fontFamily: 'Urbanist', marginBottom: '1.5%', color: 'rgba(232, 98, 254, 1)', fontSize: { xs: '1.5rem', sm: '1.5rem', md: '1.4rem' } ,filter: 'saturate(3)'}}>
                         Why Us
                    </Typography>
                    
                    <Typography  sx={{ fontFamily: 'Urbanist', lineHeight: 1.6, textAlign: 'justify', fontSize: { xs: '0.7rem', sm: '1.1rem', md: '1rem', lg: '1.3rem' } }}>
                    Seyarkai is devoted to enhancing operational efficiency through cutting-edge automation solutions. From process optimization to cost reduction, our commitment extends beyond implementation, continuously evolving to meet industry standards. We push boundaries with advanced technologies, ensuring clients stay ahead in efficiency. Harnessing the power of data, Seyarkai's algorithms empower strategic decision-making for organizations.
                    </Typography></div>
               
                    
               </Box>
               <Box  sx={{ width: { xs: '100%',sm:'70%', md: '50%',lg:'40%' }, marginTop: { xs:3 ,sm:4, md: 0 }, display: 'flex', justifyContent: { xs: 'center', md: 'center' }, alignItems: 'center' }}>
                    {loading ? (
                         <Skeleton variant='text' sx={{width:{lg:300,md:300,sm:300,xs:300},height:{lg:450,md:400,sm:470,xs:400},marginTop:{lg:-5,md:-10,sm:-10,xs:-10},marginLeft:{lg:0,md:0,sm:-4,xs:-2},marginBottom:{lg:0,md:0,sm:-10,xs:-5}}} />
                    ) : ( 
                         <div data-aos="fade-up"  data-aos-duration="1500" data-aos-once="true" data-aos-anchor-placement="top-bottom">
                         <img src={step_toward_automation} loading='lazy' alt="Stepping forward to embrace AI automation" style={{ width:290, height: 290 ,filter: 'saturate(1.8)'}} />
                         </div>
                    )}
                    
               </Box>
          </Box>
          <Box sx={{
          display: 'flex',
          maxWidth: '100%',
          marginLeft: 5,
          justifyContent: 'space-evenly',
          alignItems: 'center',
          marginRight: 5,
          gap: '3%',
          flexDirection: { xs: 'column', md: 'row' },
          marginTop: 4,
          textAlign: 'center'
          }}>

     
          <Box
          data-aos="fade-up"  data-aos-duration="1000" data-aos-once="true" data-aos-anchor-placement="top-bottom"
           sx={{
               maxWidth: { lg: 250, md: '19%', sm: "30%" },
               padding: { lg: 4, md: 3, sm: 4, xs: 2 },
               borderRadius: '20px',
               position: 'relative',
               '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    borderRadius: 'inherit',
                    padding: '1px',
                    background: 'linear-gradient(45deg, #00F0F6, #5200FF, #FF2DF7)',
                    '-webkit-mask': 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
                    '-webkit-mask-composite': 'xor',
                    maskComposite: 'exclude',
               },
               marginTop: { sm: 3, xs: 2 }
          }}> 
               {loading ? (
               <Skeleton variant='image' sx={{width:{lg:100,md:100,sm:100,xs:120},height:{lg:100,md:130,sm:100,xs:110}, margin:'auto' }} />
          ):(
               <img src={faster_and_efficient} loading='lazy' alt="Efficent and faster" style={{ width: 115, height: 115, filter: 'saturate(2)' }} />)}
               <Typography variant="h6" sx={{ fontFamily: 'Urbanist' }}>
                    Efficiency Redefined
               </Typography>
               <Typography sx={{ fontFamily: 'Urbanist', textAlign: 'center', fontSize: { xs: '0.6rem', sm: '0.7rem', md: '0.7rem', lg: '0.7rem' } }}>
                    Say goodbye to manual workflows and hello to streamlined operations. Seyarkai's automation solutions are designed to optimize processes, reduce costs, and enhance overall productivity
               </Typography>
          </Box>

          

          <Box 
          data-aos="fade-up"  data-aos-duration="1000"  data-aos-once="true" data-aos-anchor-placement="top-bottom"
          sx={{
               maxWidth: { lg: 250, md: '19%', sm: "30%" },
               padding: { lg: 3, md: 3, sm: 4, xs: 2 },
               borderRadius: '20px',
               position: 'relative',
               '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    borderRadius: 'inherit',
                    padding: '1px',
                    background: 'linear-gradient(45deg, #00F0F6, #5200FF, #FF2DF7)',
                    '-webkit-mask': 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
                    '-webkit-mask-composite': 'xor',
                    maskComposite: 'exclude',
               },
               marginTop: { sm: 3, xs: 2 }
          }}>
               {loading ? (
               <Skeleton variant='image' sx={{width:{lg:100,md:100,sm:100,xs:120},height:{lg:100,md:130,sm:100,xs:110}, margin:'auto' }} />
          ):(
               
               <img src={digital_connection} loading='lazy' alt="Digital connectivity"  style={{ width: 115, height: 115, filter: 'saturate(1.1)' }} />)}
               <Typography variant="h6" sx={{ fontFamily: 'Urbanist' }}>
                    Tailored for Every Industry
               </Typography>
               <Typography sx={{ fontFamily: 'Urbanist', textAlign: 'center', fontSize: { xs: '0.6rem', sm: '0.7rem', md: '0.7rem', lg: '0.7rem' } }}>
                    We recognize the distinctive challenges in each sector. Seyarkai offers tailored automation solutions designed to meet the specific needs of industries like healthcare, education, and mining.
               </Typography>
          </Box>

        

          <Box 
          data-aos="fade-up"  data-aos-duration="1000"  data-aos-once="true" data-aos-anchor-placement="top-bottom"
          sx={{
               maxWidth: { lg: 250, md: '19%', sm: "30%" },
               padding: { lg: 3, md: 3, sm: 4, xs: 2 },
               borderRadius: '20px',
               position: 'relative',
               '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    borderRadius: 'inherit',
                    padding: '1px',
                    background: 'linear-gradient(45deg, #00F0F6, #5200FF, #FF2DF7)',
                    '-webkit-mask': 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
                    '-webkit-mask-composite': 'xor',
                    maskComposite: 'exclude',
               },
               marginTop: { sm: 3, xs: 2 }
          }}>{loading ? (
               <Skeleton variant='image' sx={{width:{lg:100,md:100,sm:100,xs:120},height:{lg:100,md:130,sm:100,xs:110}, margin:'auto' }} />
          ):(
               <img src={precised_and_balanced} loading='lazy' alt="Precision in balance"  style={{ width: 115, height: 115, filter: 'saturate(1.3s)' }} />)}
               <Typography variant="h6" sx={{ fontFamily: 'Urbanist' }}>
                    User-Centric Approach
               </Typography>
               <Typography sx={{ fontFamily: 'Urbanist', textAlign: 'center', fontSize: { xs: '0.6rem', sm: '0.7rem', md: '0.7rem', lg: '0.7rem' } }}>
                    We prioritize user experience, crafting solutions with a deep understanding of end-users. Our goal is a smooth transition to automated processes while maintaining user satisfaction at the core.
               </Typography>
          </Box>
          </Box>
    </Box>
    </div>
    
  );
};

export default Whyus;