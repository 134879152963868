import React, { useState } from 'react';
import { Box, Typography, TextField, Button } from '@mui/material';
import logo from './images/seyarkai_vizhi.png';
import productBG from './images/productbg.webp';
import grading_and_feedback from './images/grading-and-feedback.png';
import question_paper_generation from './images/question-paper-generation.png';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { collection, addDoc } from "./firebase-connection/firebase";
import { db } from './firebase-connection/firebase';

AOS.init();

const validateEmail = (email) => {
  const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  return re.test(String(email).toLowerCase());
};

const Products = () => {
  const [email, setEmail] = useState('');

  const handleJoinWaitinglist = async () => {
    if (validateEmail(email)) {
      try {
        await addDoc(collection(db, "Landing_V1_waiting_list"), {
          email: email,
        });
        alert('Email added to the waiting list successfully!');
        setEmail(''); 
      } catch (e) {
        console.error("Error adding email: ", e);
        alert('Failed to add email to the waiting list.');
      }
    } else {
      alert('Please enter a valid email address.');
    }
  };

  return (
    <div>
      <Box
        sx={{
          display: { lg: 'flex', md: 'flex', sm: 'flex', xs: 'block' },
          margin: 'auto',
          justifyContent: 'space-evenly',
          width: { lg: '90%', md: '90%', sm: '90%', xs: '95%' },
          marginTop: 5,
          paddingBottom: 0.1,
          position: 'relative',
          borderRadius: '20px',
          '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            borderRadius: 'inherit',
            padding: '1px',
            background: 'linear-gradient(45deg, #5200FF, #FF2DF7)',
            '-webkit-mask': 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
            '-webkit-mask-composite': 'xor',
            maskComposite: 'exclude',
          },
          backgroundImage: {
            lg: `url(${productBG})`,
            md: `url(${productBG})`,
            sm: `url(${productBG})`,
            xs: 'linear-gradient(to top, white, #edf2f7)',
          },
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'right',
          backgroundSize: {
            xs: '100% 100%',
            sm: '80% 100%',
            md: '80% 100%',
            lg: ' 55% 100%',
          },
        }}
      >
        <Box id="left" sx={{ display: 'block', width: { lg: '65%', md: '68%', sm: '65%', xs: '90%' }, margin: { lg: 5, md: 5, sm: 5, xs: 'auto' } }}>
          <Typography data-aos="fade-up" data-aos-duration="1000" data-aos-once="true" data-aos-anchor-placement="top-bottom" variant="h4" sx={{ color: '#E862FE', fontFamily: 'urbanist', paddingTop: { lg: 0, md: 0, sm: 0, xs: 2 } }}>
            Products
          </Typography>
          <Box data-aos="fade-up" data-aos-duration="1000" data-aos-once="true" data-aos-anchor-placement="top-bottom" sx={{ display: 'flex', alignItems: 'center', marginTop: 3 }}>
            <img src={logo} alt="Logo" loading='lazy' style={{ width: 30, display: 'flex' }} />
            <Typography variant="h4" sx={{ fontSize: { lg: 31, md: 28, sm: 20, xs: 18 }, color: '#0053D6', display: 'flex', marginLeft: { lg: 2, md: 1, sm: 1, xs: 0.5 }, fontFamily: 'urbanist' }}>
              SeyarkAI Vizhi
            </Typography>
            <Typography variant="h4" sx={{ fontSize: { lg: 31, md: 28, sm: 19, xs: 19 }, color: '#5A5A5A', display: 'flex', fontFamily: 'urbanist', marginLeft: { lg: 1.5, md: 1.5, sm: 1.5, xs: 1 } }}>
              [ Launching Soon ]
            </Typography>
          </Box>
          <Typography data-aos="fade-up" data-aos-duration="1000" data-aos-once="true" data-aos-anchor-placement="top-bottom" variant="h3" component="h3" sx={{ textTransform: 'none', backgroundImage: 'linear-gradient(to right, #0053D6, #E862FE)', WebkitTextFillColor: 'transparent', WebkitBackgroundClip: 'text', fontWeight: '600', fontFamily: 'urbanist', marginTop: 3, fontSize: { lg: 50, md: 45, sm: 36, xs: 30 } }}>
            Enhancing administrative <br />
            efficiency in education
          </Typography>
          <Typography data-aos="fade-up" data-aos-duration="1000" data-aos-once="true" data-aos-anchor-placement="top-bottom" variant="body1" sx={{ fontFamily: 'urbanist', marginTop: 2, fontSize: { lg: 23, md: 23, sm: 16, xs: 15 }, textAlign: 'left', color: '#5A5A5A' }}>
            SeyarkAI Vizhi emerges as a groundbreaking solution, dedicated to resolving the challenges in the educational
            landscape through automation and insightful data analytics. SeyarkAI Vizhi aims to streamline the administrative
            processes, enhance efficiency, and provide educators with valuable insights, ultimately revolutionizing the
            educational experience for both teachers and students.
          </Typography>
          <Typography data-aos="fade-up" data-aos-duration="1000" data-aos-once="true" data-aos-anchor-placement="top-bottom" variant="h6" sx={{ fontFamily: 'urbanist', fontSize: { lg: 22, md: 21, sm: 16, xs: 14 }, marginTop: 2, textAlign: 'left', color: '#5A5A5A' }}>
            <b>Seize the opportunity to be a trailblazer! Secure your spot by joining our exclusive waiting list today.</b>
          </Typography>
          <Box data-aos="fade-up" data-aos-duration="1000" data-aos-once="true" data-aos-anchor-placement="top-bottom" id="input-box" sx={{ display: 'flex', width: '95%', marginTop: 2, justifyContent: 'space-between', position: 'relative', borderRadius: '20px', backgroundColor: '#fff', '&::before': { content: '""', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, borderRadius: 'inherit', padding: '1px', background: 'linear-gradient(45deg, #FF2DF7, #5200FF,#0365FF)', '-webkit-mask': 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)', '-webkit-mask-composite': 'xor', maskComposite: 'exclude', }, }}>
            <TextField
              id="email"
              variant="outlined"
              placeholder="Enter your Email to join the waitinglist"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{ flexGrow: 1, marginLeft: 2, borderRadius: '20px', '& .MuiOutlinedInput-root': { borderRadius: '20px', '& fieldset': { border: 'none', }, }, '& .MuiInputBase-input::placeholder': { fontSize: { lg: 12, md: 12, sm: 8, xs: 9 }, fontFamily: 'urbanist', opacity: 0.5, marginLeft: 0, fontWeight: 'bold', }, }}
            />
            <Button
              onClick={handleJoinWaitinglist}
              sx={{ marginLeft: 1, margin: 1, background: 'linear-gradient(to right,#4E4BE4,#E862FE)', borderRadius: '20px', padding: '0 20px', fontSize: { lg: 10, md: 10, sm: 8, xs: 9 }, color: 'white', }}
            >
              Join Waitinglist
            </Button>
          </Box>
        </Box>
        <Box data-aos="zoom-in" data-aos-duration="2000" data-aos-once='true' data-aos-anchor-placement="top-bottom" id="right" sx={{ width: { lg: '26%', md: '30%', sm: '35%' }, margin: 6, position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center', textAlign: 'center', borderRadius: '20px', '&::before': { content: '""', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, borderRadius: 'inherit', padding: '1px', background: 'linear-gradient(45deg, #5200FF, #FF2DF7)', '-webkit-mask': 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)', '-webkit-mask-composite': 'xor', maskComposite: 'exclude', }, }}>
          <Box sx={{ margin: 'auto', textAlign: 'center', paddingTop: { lg: 0, md: 3, sm: 2, xs: 2 } }}>
            <img src={grading_and_feedback} loading='lazy' alt="grading and feedback" style={{ width: 60, height: 'auto', filter: 'saturate(1.3)' }} />
            <Typography variant="h5" sx={{ fontSize: { lg: 20, md: 15 }, fontFamily: 'urbanist', color: '#5A5A5A', display: 'block', fontWeight: 'bold', opacity: 0.8, paddingTop: 1 }}>
              Automated <br /> Grading & Feedback
            </Typography>
            <Typography variant="body1" sx={{ fontFamily: 'urbanist', color: '#5A5A5A', display: 'block', opacity: 0.9, lineHeight: { lg: 1.2, md: 1.1 }, textAlign: 'center', margin: 1.3, fontSize: { lg: 20 } }}>
              Automates correction, provides feedback for efficient learning and improved outcomes.
            </Typography>
          </Box>
          <hr style={{ width: '100%', border: 'none', height: '1px', background: 'linear-gradient(to right,#FF2DF7, #5200FF, #00F0F6 )', margin: '20px 0' }} />
          <Box sx={{ margin: 'auto', textAlign: 'center', paddingBottom: { lg: 0, md: 3, sm: 2, xs: 2 } }}>
            <img src={question_paper_generation} loading='lazy' alt="question paper generation" style={{ width: 60, height: 'auto', filter: 'saturate(1.3)' }} />
            <Typography variant="h5" sx={{ fontSize: { lg: 20, md: 15 }, fontFamily: 'urbanist', color: '#5A5A5A', display: 'block', fontWeight: 'bold', opacity: 0.8, paddingTop: 1 }}>
              Automated Question <br /> Paper Generation
            </Typography>
            <Typography variant="body1" sx={{ fontFamily: 'urbanist', color: '#5A5A5A', display: 'block', opacity: 0.9, lineHeight: { lg: 1.2, md: 1.1 }, textAlign: 'center', margin: 1.5, fontSize: { lg: 20 } }}>
              Automates question framing, ensures fairness in standardized evaluation.
            </Typography>
          </Box>
        </Box>
      </Box>
    </div>
  );
};

export default Products;
