import { initializeApp } from "firebase/app";
import { getFirestore, collection, addDoc } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyCxu9ys7qcL4PBDzsrluFBthMQFOzJsoWg",
  authDomain: "seyarkai-7a6a8.firebaseapp.com",
  projectId: "seyarkai-7a6a8",
  storageBucket: "seyarkai-7a6a8.appspot.com",
  messagingSenderId: "469243820829",
  appId: "1:469243820829:web:b543fe4ccc7f4b30603ff4",
  measurementId: "G-94Z64CFRVK"
};


const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db, addDoc, collection };
