import { Box, Typography } from '@mui/material';
import AOS from 'aos';
import 'aos/dist/aos.css'; 
AOS.init();

const Services = () => {
  return (
    <div id='Services'>
      <Box sx={{ margin:'auto',marginTop:5,width: { lg: '90%', md: '90%', sm: '90%', xs: '95%' } , paddingTop: 4,alignItems: 'center'}}>
        <Box sx={{backgroundImage: 'linear-gradient(to bottom, white, #EEF4FB)',backgroundSize:{lg:'100% 50%',md:'100% 50%',sm:'100% 50%',xs:'100% 50%'},backgroundRepeat:'no-repeat',borderRadius:'20px',overflow:'hidden',paddingBottom: 5,marginTop: { xs: -0.7 },marginBottom: { xs: -5, sm:-5, md: -5, lg: -3 },filter: 'saturate(2)'  }}>
            <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexDirection="column"
        >
       
            <div data-aos="fade-up"  data-aos-duration="1000" data-aos-once="true" data-aos-anchor-placement="top-bottom"><Typography sx={{ color: '#E862FE', fontFamily: 'Urbanist', fontSize: {lg:27,md:24,sm:22,xs:20}, paddingBottom: {lg:3,md:3,sm:3,xs:2}, textAlign: 'center', paddingTop: 4 }}>What We Are Good At</Typography></div></Box>
            <div data-aos="fade-up"  data-aos-duration="1000" data-aos-once="true" data-aos-anchor-placement="top-bottom">
            <Box sx={{ display: {lg:'flex',md:'flex',sm:'flex',xs:'flex'},flexDirection:{lg:'row',md:'row',sm:'row',xs:'column'}, marginRight: {lg:14,md:9,sm:7,xs:5}, marginLeft: {lg:14,md:9,sm:7,xs:5},backgroundSize:{lg:'80%'}, gap: {lg:2.5,md:2,sm:1.5,xs:1}, alignItems: 'center', position: 'relative' }}>
           
              <Typography sx={{ flex: 0.4, fontFamily: 'Urbanist', fontSize: {lg:38,md:32,sm:26,xs:23}, color: '#5A5A5A', lineHeight: '130%',fontWeight:{xs:400},marginRight:{lg:5} ,textAlign:{lg:'left',md:'left',sm:'center',xs:'center'}}}>We leverage Intelligence to digitally transform companies.</Typography>
           
              <Typography sx={{ flex: 0.6, fontFamily: 'Urbanist', fontSize: {lg:24,md:20,sm:16,xs:13}, color: '#5A5A5A', lineHeight: '145%', fontWeight: {lg:300,md:300,sm:400,xs:500},textAlign:{lg:'left',md:'left',sm:'center',xs:'center'}}}>We utilize cutting-edge technologies and algorithms to redefine operational standards, ensuring our clients benefit from the latest innovations. Our commitment to staying at the forefront positions them for sustained success.</Typography>
            </Box></div>
          
            <div className='services'>
            <Box sx={{  marginTop:{lg:5,md:5,sm:5,xs:5}, zIndex: '2'}}>
            <div data-aos="fade-up"  data-aos-duration="1000" data-aos-delay='50' data-aos-once="true" data-aos-anchor-placement="top-bottom">
              <Box sx={{ display: {lg:'flex',md:'flex',sm:'flex',xs:'flex'},flexDirection:{lg:'row',md:'row',sm:'column',xs:'column'},marginRight: {lg:15,md:12,sm:9,xs:4}, marginLeft: {lg:15,md:12,sm:9,xs:4}, justifyContent:{sm:'center',xs:'center'}, gap:{lg:4,md:3,sm:1,xs:0.5}, paddingLeft: {lg:6,md:5,sm:4,xs:3},paddingRight:{lg:8,md:5,sm:4,xs:3},paddingTop:{lg:5,md:3,sm:1,xs:1},paddingBottom:{lg:5,md:3,sm:2,xs:1}, alignItems: 'center',backgroundColor: 'rgba(255, 255, 255, 0.1)',backdropFilter: 'blur(5px)', marginBottom: 3, borderRadius: '20px', position: 'relative', '&::before': { content: '""', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, borderRadius: 'inherit', padding: '1px', background: 'linear-gradient(45deg, #5200FF,#FF2DF7)', '-webkit-mask': 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)', '-webkit-mask-composite': 'xor', maskComposite: 'exclude', } }}>
                <Typography sx={{ backgroundImage: 'linear-gradient(to right, #FFFFFF, #E862FE)', fontSize: {lg:36,md:36,sm:40,xs:33}, WebkitTextFillColor: 'transparent', WebkitBackgroundClip: 'text', fontFamily: 'Urbanist' }}>01</Typography>
                <Typography sx={{ fontFamily: 'Urbanist', fontSize: {lg:32,md:28,sm:25,xs:22}, paddingLeft: {lg:4,md:3,sm:0,xs:0}, lineHeight: '140%', color: '#5A5A5A', flex: '0.25',textAlign:{xs:'center'} }}>AI-Driven <span style={{ backgroundImage: 'linear-gradient(to right, #0053D6, #E862FE)', WebkitTextFillColor: 'transparent', WebkitBackgroundClip: 'text' }}>Personalized </span> Solutions</Typography>
                <Typography sx={{ fontFamily: 'Urbanist', textAlign:{sm:'center',xs:'center'},fontSize: {lg:22,md:18,sm:18,xs:13}, marginRight: {lg:5,md:3}, paddingLeft: {lg:2,md:1,sm:0}, lineHeight: {lg:'140%',md:'130%'}, color: "#5A5A5A", fontWeight: '500', flex: '0.78' }}>We specialize in crafting AI-driven personalized solutions tailored to meet your unique needs. Our dedicated team of experts harnesses the power of artificial intelligence to create innovative solutions that go beyond expectations.</Typography>
              </Box></div>

              <div data-aos="fade-up"  data-aos-duration="1000" data-aos-once="true" data-aos-anchor-placement="top-bottom">
              <Box sx={{ display: {lg:'flex',md:'flex',sm:'flex',xs:'flex'},flexDirection:{lg:'row',md:'row',sm:'column',xs:'column'},marginRight: {lg:15,md:12,sm:9,xs:4}, marginLeft: {lg:15,md:12,sm:9,xs:4}, justifyContent:{sm:'center',xs:'center'}, gap:{lg:4,md:3,sm:1,xs:0.5}, paddingLeft: {lg:6,md:5,sm:4,xs:3},paddingRight:{lg:8,md:5,sm:4,xs:3},paddingTop:{lg:5,md:3,sm:1,xs:1},paddingBottom:{lg:5,md:3,sm:2,xs:1}, alignItems: 'center',backgroundColor: 'rgba(255, 255, 255, 0.1)',backdropFilter: 'blur(5px)', marginBottom: 3, borderRadius: '20px', position: 'relative', '&::before': { content: '""', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, borderRadius: 'inherit', padding: '1px', background: 'linear-gradient(45deg, #5200FF,#FF2DF7)', '-webkit-mask': 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)', '-webkit-mask-composite': 'xor', maskComposite: 'exclude', } }}>
                <Typography sx={{ backgroundImage: 'linear-gradient(to right, #FFFFFF, #E862FE)', fontSize: {lg:36,md:36,sm:40,xs:33}, WebkitTextFillColor: 'transparent', WebkitBackgroundClip: 'text', fontFamily: 'Urbanist' }}>02</Typography>
                <Typography sx={{ fontFamily: 'Urbanist', fontSize: {lg:32,md:28,sm:25,xs:22}, paddingLeft: {lg:4,md:3,sm:0,xs:0}, lineHeight: '140%', color: '#5A5A5A', flex: '0.25' ,textAlign:{xs:'center'}}}>AI-Driven <span style={{ backgroundImage: 'linear-gradient(to right, #0053D6, #E862FE)', WebkitTextFillColor: 'transparent', WebkitBackgroundClip: 'text' }}>Manufacturing </span> Solutions</Typography>
                <Typography sx={{ fontFamily: 'Urbanist', textAlign:{sm:'center',xs:'center'},fontSize: {lg:22,md:18,sm:18,xs:13}, marginRight: {lg:5,md:3}, paddingLeft: {lg:2,md:1,sm:0}, lineHeight: {lg:'140%',md:'130%'}, color: "#5A5A5A", fontWeight: '500', flex: '0.78' }}>In the swift realm of manufacturing, Seyarkai prioritizes efficiency. We introduce advanced AI-driven solutions, elevating productivity and precision, and setting new benchmarks in the industry.</Typography>
              </Box></div>

              <div data-aos="fade-up"  data-aos-duration="1000" data-aos-once="true" data-aos-anchor-placement="top-bottom">
              <Box sx={{ display: {lg:'flex',md:'flex',sm:'flex',xs:'flex'},flexDirection:{lg:'row',md:'row',sm:'column',xs:'column'},marginRight: {lg:15,md:12,sm:9,xs:4}, marginLeft: {lg:15,md:12,sm:9,xs:4}, justifyContent:{sm:'center',xs:'center'}, gap:{lg:4,md:3,sm:1,xs:0.5}, paddingLeft: {lg:6,md:5,sm:4,xs:3},paddingRight:{lg:8,md:5,sm:4,xs:3},paddingTop:{lg:5,md:3,sm:1,xs:1},paddingBottom:{lg:5,md:3,sm:2,xs:1}, alignItems: 'center',backgroundColor: 'rgba(255, 255, 255, 0.1)',backdropFilter: 'blur(5px)', marginBottom: 3, borderRadius: '20px', position: 'relative', '&::before': { content: '""', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, borderRadius: 'inherit', padding: '1px', background: 'linear-gradient(45deg, #5200FF,#FF2DF7)', '-webkit-mask': 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)', '-webkit-mask-composite': 'xor', maskComposite: 'exclude', } }}>
                <Typography sx={{ backgroundImage: 'linear-gradient(to right, #FFFFFF, #E862FE)', fontSize: {lg:36,md:36,sm:40,xs:33}, WebkitTextFillColor: 'transparent', WebkitBackgroundClip: 'text', fontFamily: 'Urbanist' }}>03</Typography>
                <Typography sx={{ fontFamily: 'Urbanist', fontSize: {lg:32,md:28,sm:25,xs:22}, paddingLeft: {lg:4,md:3,sm:0,xs:0}, lineHeight: '140%', color: '#5A5A5A', flex: '0.25',textAlign:{xs:'center'} }}><span style={{ backgroundImage: 'linear-gradient(to right, #0053D6, #E862FE)', WebkitTextFillColor: 'transparent', WebkitBackgroundClip: 'text' }}>Workshops </span> on AI</Typography>
                <Typography sx={{ fontFamily: 'Urbanist', textAlign:{sm:'center',xs:'center'},fontSize: {lg:22,md:18,sm:18,xs:13}, marginRight: {lg:5,md:3}, paddingLeft: {lg:2,md:1,sm:0}, lineHeight: {lg:'140%',md:'130%'}, color: "#5A5A5A", fontWeight: '500', flex: '0.78' }}>Our workshops demystify AI concepts, impart practical knowledge, and empower your workforce. Led by seasoned professionals, these hands-on sessions equip your team with skills to confidently navigate the AI landscape.</Typography>
              </Box></div>

              <div data-aos="fade-up"  data-aos-duration="1000" data-aos-once="true" data-aos-anchor-placement="top-bottom">
              <Box sx={{ display: {lg:'flex',md:'flex',sm:'flex',xs:'flex'},flexDirection:{lg:'row',md:'row',sm:'column',xs:'column'},marginRight: {lg:15,md:12,sm:9,xs:4}, marginLeft: {lg:15,md:12,sm:9,xs:4}, justifyContent:{sm:'center',xs:'center'}, gap:{lg:4,md:3,sm:1,xs:0.5}, paddingLeft: {lg:6,md:5,sm:4,xs:3},paddingRight:{lg:8,md:5,sm:4,xs:3},paddingTop:{lg:5,md:3,sm:1,xs:1},paddingBottom:{lg:5,md:3,sm:2,xs:1}, alignItems: 'center',backgroundColor: 'rgba(255, 255, 255, 0.1)',backdropFilter: 'blur(5px)', marginBottom: 3, borderRadius: '20px', position: 'relative', '&::before': { content: '""', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, borderRadius: 'inherit', padding: '1px', background: 'linear-gradient(45deg, #5200FF,#FF2DF7)', '-webkit-mask': 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)', '-webkit-mask-composite': 'xor', maskComposite: 'exclude', } }}>
                <Typography sx={{ backgroundImage: 'linear-gradient(to right, #FFFFFF, #E862FE)', fontSize: {lg:36,md:36,sm:40,xs:33}, WebkitTextFillColor: 'transparent', WebkitBackgroundClip: 'text', fontFamily: 'Urbanist' }}>04</Typography>
                <Typography sx={{ fontFamily: 'Urbanist', fontSize: {lg:32,md:28,sm:25,xs:22}, paddingLeft: {lg:4,md:3,sm:0,xs:0}, lineHeight: '140%', color: '#5A5A5A', flex: '0.25',textAlign:{xs:'center'} }}>AI-Assisted <span style={{ backgroundImage: 'linear-gradient(to right, #0053D6, #E862FE)', WebkitTextFillColor: 'transparent', WebkitBackgroundClip: 'text' }}>Chat Bots </span></Typography>
                <Typography sx={{ fontFamily: 'Urbanist', textAlign:{sm:'center',xs:'center'},fontSize: {lg:22,md:18,sm:18,xs:13}, marginRight: {lg:5,md:3}, paddingLeft: {lg:2,md:1,sm:0}, lineHeight: {lg:'140%',md:'130%'}, color: "#5A5A5A", fontWeight: '500', flex: '0.78' }}>Enhance support with Seyarkai's AI chatbots. These bots efficiently understand, learn, and respond. Seamlessly integrate AI to elevate user experience, streamlining customer interactions for optimal engagement.</Typography>
              </Box></div>   
            </Box>
            </div>
          </Box>
      </Box>
    </div>
  );
};

export default Services;
