import { Box, Typography } from '@mui/material'
import Ourvision_ellipse from '../comp/images/Ourvision-ellipse.webp'
import flagbearer_of_innovation from '../comp/images/flagbearer-of-innovation.webp'
const Ourvision = () => {


  return (
    <div id='Our_Mission'>
    <Box sx={{margin:'auto',
        width: { lg: '90%', md: '90%', sm: '90%', xs: '95%' }, 
        marginTop:5,
        alignItems: 'center',
        backgroundImage: {lg:`url(${Ourvision_ellipse})`,md:`url(${Ourvision_ellipse})`,sm:'linear-gradient(to top, white, #edf2f7)',xs:'linear-gradient(to top, white, #edf2f7)'}, backgroundRepeat: 'no-repeat', backgroundPosition: 'left', backgroundSize: { xs: '100% 100%',sm : '100% 100%', md: '60% 100%',lg: ' 55% 100%'} ,
        borderRadius: '20px',
        position: 'relative', 
        '&::before': { content: '""', position: 'absolute', top: 0, left: 0, right: 0, bottom: 0, borderRadius: 'inherit', padding: '1px', background: 'linear-gradient(45deg, #5200FF,#FF2DF7)', '-webkit-mask': 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)', '-webkit-mask-composite': 'xor', maskComposite: 'exclude', } }}>

        <Box sx={{display:{lg:'flex',md:'flex',sm:'block',xs:'block'},justifyContent:'space-between',alignItems:'center',textAlign:{lg:'left',md:'left',sm:'center',xs:'center'},paddingTop:{lg:0,md:1,sm:3,xs:3},paddingBottom:{lg:0,md:1,sm:3,xs:3}}}>
           
            <Box sx={{flex:{lg:0.65,md:0.65,sm:0,xs:0},paddingLeft:{lg:25,md:20,sm:12,xs:2},paddingRight:{lg:0,md:0,sm:12,xs:2},marginBottom:{sm:0,xs:1},maxWidth:{lg:'45%',md:'50%',sm:'100%'}}}>
             <Typography  data-aos="fade-up"  data-aos-duration="1000" data-aos-once="true"  data-aos-anchor-placement="top-bottom"sx={{fontSize:{lg:35,md:30,sm:30,xs:26},fontFamily:'Urbanist',paddingBottom:{sm:1}}}>Our Vision</Typography>
            <Typography   data-aos="fade-up"  data-aos-duration="1000" data-aos-once="true"  data-aos-anchor-placement="top-bottom"  sx={{fontSize:{lg:21,md:18,sm:19,xs:16},fontFamily:'Urbanist',lineHeight:'140%'}}>To elevate people’s lives by providing intelligent automation solutions that simplify, optimize, and enhance their daily tasks and workflows.</Typography>
    
            </Box>
            <Box  data-aos="fade-up"  data-aos-duration="1000" data-aos-once="true"  data-aos-anchor-placement="top-bottom"  sx={{flex:{lg:0.35,md:0.35,sm:0,xs:0},marginBottom:{lg:-4,md:-4,sm:-6,xs:-3},marginTop:{lg:-2,md:-2}}}>
                <div className='Ourvision_img'><img src={flagbearer_of_innovation} loading='lazy' alt='Ourvision_img' /></div>
            </Box>
        </Box>     
     </Box>
    </div>
  )
}

export default Ourvision
