import React, { Component } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import ShoppingCartOutlinedIcon from '@mui/icons-material/ShoppingCartOutlined';
import SchoolOutlinedIcon from '@mui/icons-material/SchoolOutlined';
import FastfoodOutlinedIcon from '@mui/icons-material/FastfoodOutlined';
import HealthAndSafetyOutlinedIcon from '@mui/icons-material/HealthAndSafetyOutlined';
import PrecisionManufacturingOutlinedIcon from '@mui/icons-material/PrecisionManufacturingOutlined';
import SportsBasketballOutlinedIcon from '@mui/icons-material/SportsBasketballOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import MicExternalOnOutlinedIcon from '@mui/icons-material/MicExternalOnOutlined';
import CardTravelOutlinedIcon from '@mui/icons-material/CardTravelOutlined';
import AOS from 'aos';
import 'aos/dist/aos.css'; 
AOS.init();



class IndustriesWeServe extends Component {
  render() {
    return (
      <Box id="Industries_We_Serve"
        sx={{
          width: { lg: '90%', md: '90%', sm: '90%', xs: '95%' },
          borderRadius: '20px',
          paddingTop: 5,
          paddingBottom: 5,
          margin: 'auto',
          position: 'relative',
          background: 'linear-gradient(to right, #4E4BE4,#A358F2)',
          maskComposite: 'exclude',
          display: 'flex',
          flexDirection: 'column',
          filter: 'saturate(2)',
          justifyContent: 'center',
          alignItems: 'center',
          marginTop:0,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            margin: 'auto auto',
            width: '70vw',
            maxWidth: '100%',
          }}
        >
          <Typography
            data-aos="fade-up"  data-aos-duration="1000" data-aos-once="true" data-aos-anchor-placement="top-bottom"
            variant="h5"
            sx={{ 
              color:'white',
              filter: 'saturate(1.5)',
              fontSize: { lg: 23, md: 20, sm: 19, xs: 16 },
              fontFamily: 'montserrat',
            }}
          >
            Industries That We Serve
          </Typography>
        </Box>
        <Box
          sx={{
            display: { lg: 'flex', md: 'flex', sm: 'block', xs: 'block' },
            justifyContent: { lg: 'space-evenly', md: 'space-evenly', sm: 'center' },
            alignItems: 'left',
          }}
        >
          <Box  data-aos="fade-up"  data-aos-duration="1000" data-aos-once="true"  data-aos-anchor-placement="top-bottom" sx={{ maxWidth: { lg: '50%', md: '35%', sm: '90%' }, marginLeft: { lg: 3, md: 0, sm: 4 }, justifyContent: 'center', textAlign: 'left' }}>
            <Typography
              
              variant="body1"
              sx={{
                padding: { lg: 4, md: 1.2, sm: 2, xs: 2 },
                fontFamily: 'montserrat',
                fontSize: { lg: 24, md: 21, sm: 18, xs: 16 },
                lineHeight: { lg: 1.4, md: 1.8, sm: 1.8, xs: 1.9 },
                textTransform: 'none',
                color: 'white',
                textAlign: { lg: 'left', md: 'left', sm: 'center',xs:'center' },
              }}
            >
              We use technology to transform industries, seamlessly creating a blend of innovation, efficiency, and growth.
            </Typography>
          </Box>
          <Box data-aos="fade-up"  data-aos-duration="1000" data-aos-once="true"  data-aos-anchor-placement="top-bottom" sx={{ maxWidth: { lg: '50%', md: '45%', sm: '80%', xs: '85%' } }}>
            <Typography
              variant="body2"

              sx={{
                padding: { lg: 3, md: 2 },
                fontFamily: 'montserrat',
                fontSize: { lg: 15, md: 16, sm: 13, xs: 11  },
                lineHeight: { lg: 1.5, md: 1.1 },
                textTransform: 'none',
                color: 'white',
                marginTop: { lg: '0.9vw', md: '0.4vw' },
                textAlign: { lg: 'unset', md: 'unset', sm: 'center', xs: 'center' },
                alignContent: 'center',
                marginLeft:{lg:0,md:0,sm:16,xs:'20%'}
              }}
            >
              Harnessing cutting-edge developments in artificial intelligence and machine learning, we provide customized solutions addressing vital business challenges, creating tangible impact for our customers.
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            display: 'flex',
            maxWidth: '95%',
            marginLeft: { lg: 10, md: 6, sm: 13, xs:6},
            justifyContent: { lg: 'start', md: 'start', sm: 'start', xs: 'center' },
            alignItems: 'flex-start',
            marginTop: 2,
          }}
        >
          <Grid container spacing={4}>
            

            <Grid item xs={5} sm={6} md={4} lg={4} sx={{ paddingLeft: { lg: 10, md: 10 } }}>
              <Box  data-aos="fade-up"  data-aos-duration="1000" data-aos-once="true" data-aos-anchor-placement="top-bottom"  sx={{ display: 'flex', justifyContent: 'start', padding: 1 }}>
              <HealthAndSafetyOutlinedIcon sx={{ color: 'white', marginRight: {lg:1,md:1,sm:1,xs:0.5},fontSize:{lg:40,md:40,sm:40,xs:30}}} />
                <Typography variant="h6" sx={{ color: 'white', marginLeft: {lg:1,md:1,sm:1,xs:0.3}, paddingTop: { lg: 0.5, md: 1, sm: 1.5, xs: 0.5 }, fontFamily: 'Urbanist', fontSize: { lg: 22, md: 18, sm: 15, xs: 13 } }}>
                  Healthcare
                </Typography>
              </Box>
              <Divider data-aos="fade-up"  data-aos-duration="1000" data-aos-once="true" data-aos-anchor-placement="top-bottom" sx={{ background: 'linear-gradient(to right, #00F0FF, #5200FF, #FF2DF7)', height: '1.5px', marginY: 1 ,marginLeft:0.5,marginRight:{lg:5,md:5,sm:7,xs:-2}}} />
            </Grid>
          
            <Grid item xs={5} sm={6} md={4} lg={4}>
              <Box data-aos="fade-up"  data-aos-duration="1000" data-aos-once="true" data-aos-anchor-placement="top-bottom" sx={{ display: 'flex', justifyContent: 'start', padding: 1 }}>
              <PrecisionManufacturingOutlinedIcon sx={{ color: 'white', marginRight: {lg:1,md:1,sm:1,xs:0.5},fontSize:{lg:40,md:40,sm:40,xs:30}}} />
                <Typography variant="h6" sx={{ color: 'white', marginLeft: {lg:1,md:1,sm:1,xs:0.5}, paddingTop: { lg: 0.5, md: 1, sm: 1.5, xs: 1 }, fontFamily: 'Urbanist', fontSize: { lg: 22, md: 18, sm: 15, xs: 13 } }}>
                  Manufacturing
                </Typography>
              </Box>
              <Divider data-aos="fade-up"  data-aos-duration="1000" data-aos-once="true" data-aos-anchor-placement="top-bottom" sx={{ background: 'linear-gradient(to right, #00F0FF, #5200FF, #FF2DF7)', height: '1.5px', marginY: 1 ,marginLeft:0.5,marginRight:{lg:5,md:5,sm:7,xs:-3}}} />
            </Grid>

            <Grid item xs={5} sm={6} md={4} lg={4}>
              <Box data-aos="fade-up"  data-aos-duration="1000" data-aos-once="true" data-aos-anchor-placement="top-bottom" sx={{ display: 'flex', justifyContent: 'start', padding: 1 }}>
              <SchoolOutlinedIcon sx={{ color: 'white', marginRight: {lg:1,md:1,sm:1,xs:0.5},fontSize:{lg:40,md:40,sm:40,xs:30}}} />
                <Typography variant="h6" sx={{ color: 'white', marginLeft: {lg:1,md:1,sm:1,xs:0.5}, paddingTop: { lg: 0.5, md: 1, sm: 1.5, xs: 1 }, fontFamily: 'Urbanist', fontSize: { lg: 22, md: 18, sm: 15, xs: 13 } }}>
                  Education
                </Typography>
              </Box>
              <Divider data-aos="fade-up"  data-aos-duration="1000" data-aos-once="true" data-aos-anchor-placement="top-bottom" sx={{ background: 'linear-gradient(to right, #00F0FF, #5200FF, #FF2DF7)', height: '1.5px', marginY: 1 ,marginLeft:0.5,marginRight:{lg:5,md:5,sm:7,xs:-2}}} />
            </Grid>

            <Grid item xs={5} sm={6} md={4} lg={4}>
              <Box data-aos="fade-up"  data-aos-duration="1000" data-aos-once="true" data-aos-anchor-placement="top-bottom"  sx={{ display: 'flex', justifyContent: 'start', padding: 1 }}>
              <LocalShippingOutlinedIcon sx={{ color: 'white', marginRight: {lg:1,md:1,sm:1,xs:0.5},fontSize:{lg:40,md:40,sm:40,xs:30}}} />
                <Typography variant="h6" sx={{ color: 'white', marginLeft: {lg:1,md:1,sm:1,xs:0.5}, paddingTop: { lg: 0.5, md: 1, sm: 1.5, xs: 1 }, fontFamily: 'Urbanist', fontSize: { lg: 22, md: 18, sm: 15, xs: 13 } }}>
                  Transportation
                </Typography>
              </Box>
              <Divider data-aos="fade-up"  data-aos-duration="1000" data-aos-once="true" data-aos-anchor-placement="top-bottom"  sx={{ background: 'linear-gradient(to right, #00F0FF, #5200FF, #FF2DF7)', height: '1.5px', marginY: 1 ,marginLeft:0.5,marginRight:{lg:5,md:5,sm:7,xs:-3}}} />
            </Grid>

            

            <Grid item xs={5} sm={6} md={4} lg={4}>
              <Box  data-aos="fade-up"  data-aos-duration="1000" data-aos-once="true" data-aos-anchor-placement="top-bottom" sx={{ display: 'flex', justifyContent: 'start', padding: 1 }}>
              <SportsBasketballOutlinedIcon sx={{ color: 'white', marginRight: {lg:1,md:1,sm:1,xs:0.5},fontSize:{lg:40,md:40,sm:40,xs:30}}} />
                <Typography variant="h6" sx={{ color: 'white', marginLeft: {lg:1,md:1,sm:1,xs:0.5}, paddingTop: { lg: 0.5, md: 1, sm: 1.5, xs: 1 }, fontFamily: 'Urbanist', fontSize: { lg: 22, md: 18, sm: 15, xs: 13 } }}>
                  Sports
                </Typography>
              </Box>
              <Divider data-aos="fade-up"  data-aos-duration="1000" data-aos-once="true" data-aos-anchor-placement="top-bottom"  sx={{ background: 'linear-gradient(to right, #00F0FF, #5200FF, #FF2DF7)', height: '1.5px', marginY: 1 ,marginLeft:0.5,marginRight:{lg:5,md:5,sm:7,xs:-2} }} />
            </Grid>

            <Grid item xs={5} sm={6} md={4} lg={4}>
              <Box data-aos="fade-up"  data-aos-duration="1000" data-aos-once="true" data-aos-anchor-placement="top-bottom"  sx={{ display: 'flex', justifyContent: 'start', padding: 1 }}>
              <MicExternalOnOutlinedIcon sx={{ color: 'white', marginRight: {lg:1,md:1,sm:1,xs:0.5},fontSize:{lg:40,md:40,sm:40,xs:30}}} />
                <Typography variant="h6" sx={{ color: 'white', marginLeft: {lg:1,md:1,sm:1,xs:0.5}, paddingTop: { lg: 0.5, md: 1, sm: 1.5, xs: 1 }, fontFamily: 'Urbanist', fontSize: { lg: 22, md: 18, sm: 15, xs: 13 } }}>
                  Entertainment
                </Typography>
              </Box>
              <Divider data-aos="fade-up"  data-aos-duration="1000" data-aos-once="true" data-aos-anchor-placement="top-bottom"  sx={{ background: 'linear-gradient(to right, #00F0FF, #5200FF, #FF2DF7)', height: '1.5px', marginY: 1 ,marginLeft:0.5,marginRight:{lg:5,md:5,sm:7,xs:-3}}} />
            </Grid>

            <Grid item xs={5} sm={6} md={4} lg={4}>
              <Box data-aos="fade-up"  data-aos-duration="1000" data-aos-once="true" data-aos-anchor-placement="top-bottom"  sx={{ display: 'flex', justifyContent: 'start', padding: 1 }}>
              <FastfoodOutlinedIcon sx={{ color: 'white', marginRight: {lg:1,md:1,sm:1,xs:0.5},fontSize:{lg:40,md:40,sm:40,xs:30}}} />
                <Typography variant="h6" sx={{ color: 'white', marginLeft: {lg:1,md:1,sm:1,xs:0.5}, paddingTop: { lg: 0.5, md: 1, sm: 1.5, xs: 0.3 }, fontFamily: 'Urbanist', fontSize: { lg: 22, md: 18, sm: 15, xs: 13 } }}>
                  Foods & Beverages
                </Typography>
              </Box>
              <Divider  data-aos="fade-up"  data-aos-duration="1000" data-aos-once="true" data-aos-anchor-placement="top-bottom" sx={{ background: 'linear-gradient(to right, #00F0FF, #5200FF, #FF2DF7)', height: '1.5px', marginY: 1 ,marginLeft:0.5,marginRight:{lg:5,md:5,sm:7,xs:-2}}} />
            </Grid>

            <Grid item xs={5} sm={6} md={4} lg={4} >
              <Box  data-aos="fade-up"  data-aos-duration="1000" data-aos-once="true" data-aos-anchor-placement="top-bottom" sx={{ display: 'flex', justifyContent: 'start', padding: 1 }}>
              <ShoppingCartOutlinedIcon sx={{ color: 'white', marginRight: {lg:1,md:1,sm:1,xs:0.5},fontSize:{lg:40,md:40,sm:40,xs:30}}} />
                <Typography
                  variant="h6"
                  sx={{ 
                    color: 'white', 
                    marginLeft: {lg:1,md:1,sm:1,xs:0.5}, 
                    paddingTop: { lg: 0.5, md: 1, sm: 1.5, xs: 0.3 }, 
                    fontFamily: 'Urbanist',
                    fontSize: { lg: 22, md: 18, sm: 15, xs: 13} 
                  }}
                >
                  Retail & E-Commerce
                </Typography>
              </Box>
              <Divider data-aos="fade-up"  data-aos-duration="1000" data-aos-once="true"  data-aos-anchor-placement="top-bottom" sx={{ background: 'linear-gradient(to right, #00F0FF, #5200FF, #FF2DF7)', height: '1.5px', marginY: 1 ,marginLeft:0.5,marginRight:{lg:5,md:5,sm:7,xs:-3}}} />
            </Grid>
            

            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Box  data-aos="fade-up"  data-aos-duration="1000" data-aos-once="true" data-aos-anchor-placement="top-bottom"  sx={{ display: 'flex', justifyContent: { lg: 'start', md: 'start', sm: 'center',xs:'center' }, padding: 1, marginLeft: { sm: -14, lg: 0, md: 0 ,xs:-3} }}>
              <CardTravelOutlinedIcon sx={{ color: 'white', marginRight: {lg:1,md:1,sm:1,xs:0.5},fontSize:{lg:40,md:40,sm:40,xs:30}}} />
                <Typography variant="h6" sx={{ color: 'white', marginLeft: {lg:1,md:1,sm:1,xs:0.5}, paddingTop: { lg: 0.5, md: 1, sm: 1.5, xs: 1 }, fontFamily: 'Urbanist', fontSize: { lg: 22, md: 18, sm: 15, xs: 13 } }}>
                  Travel & Hospitality
                </Typography>
              </Box>
              <Divider data-aos="fade-up"  data-aos-duration="1000" data-aos-once="true" data-aos-anchor-placement="top-bottom"  sx={{ background: 'linear-gradient(to right, #00F0FF, #5200FF, #FF2DF7)', height: '1.5px', marginY: 1 ,marginLeft:{lg:0.5,md:0.5,sm:'13%',xs:8},marginRight:{lg:5,md:5,sm:'30%',xs:10}}} />
            </Grid>
          </Grid>
        </Box>
      </Box>
    );
  }
}

export default IndustriesWeServe;
