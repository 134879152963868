import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import rapid_progress from '../comp/images/rapid-progress.webp'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AOS from 'aos';
import 'aos/dist/aos.css'; 
AOS.init();



const ScrollUp = () => {
  const element = document.getElementById('Contact_Us');
  const offset = 100; // Adjust this value as needed
  const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
  const offsetPosition = elementPosition - offset;

  window.scrollTo({
    top: offsetPosition,
    behavior: 'smooth'
  });
};
const Project = () => {
  return (
    <div>
       <Box sx={{
          margin:'auto',
          width: { lg: '90%', md: '90%', sm: '90%', xs: '95%' },
          padding:{lg:0,md:0,sm:5,xs:5},
          borderRadius: '20px',
          marginTop: {lg:4,md:4,sm:6,xs:4},
          position: 'relative',
          background: 'linear-gradient(to right, #4E4BE4,#A358F2)',
          maskComposite: 'exclude',filter: 'saturate(2)'}}>
            
            <Box sx={{margin:'auto',display:{lg:'flex',md:'flex',sm:'flex',xs:'flex'},flexDirection:{lg:'row',md:'row',sm:'column-reverse',xs:'column-reverse'},alignItems:'center',gap:{lg:4,md:0}}}>
                    <Box   data-aos="fade-up"  data-aos-duration="1000" data-aos-once="true"  data-aos-anchor-placement="top-bottom"  sx={{marginTop:{lg:-2,md:2,sm:1},marginBottom:{lg:-2,md:2,sm:-4,xs:-4},flex:{lg:0.4,md:0.45,sm:0},}}  className='Projectstyle' > <img src={rapid_progress} alt='success and rapid progress' loading='lazy'  /></Box>
                    <Box sx={{justifyContent:'center',flex:{lg:0.7,md:0.55},textAlign:'center',marginRight:{lg:0,md:5},marginTop:{md:2,sm:-1,xs:-2}}}>
                        <Typography  data-aos="fade-up"  data-aos-duration="1000" data-aos-once="true"  data-aos-anchor-placement="top-bottom"  sx={{color:'white',fontSize:{lg:34,md:28,sm:26,xs:24},fontFamily:'Urbanist'}}><div className='Projecthead'>Have a Project in Mind ?</div></Typography>
                        <Typography  data-aos="fade-up"  data-aos-duration="1000" data-aos-once="true"  data-aos-anchor-placement="top-bottom"   sx={{color:'white',fontSize:{lg:36,md:32,sm:30,xs:23},fontFamily:'Urbanist',lineHeight:'140%',fontWeight:600,marginBottom:{lg:3,md:2,sm:2,xs:1},}}><div className='Projectpara'>Let’s Make Something Great Together</div></Typography>
                        <Box  data-aos="fade-up"  data-aos-duration="1000" data-aos-once="true"  data-aos-anchor-placement="top-bottom" >
                          <Button  variant='contained' onClick={ScrollUp} sx={{marginBottom:{lg:2,md:2,sm:-2,xs:1},borderRadius:'20px',backgroundColor:'black',marginTop:{lg:0,md:0,sm:0,xs:1},fontFamily:'Urbanist',fontWeight:500,fontSize:{lg:15,md:14,sm:17,xs:12},'&:hover': {fontWeight:600,color:'black',backgroundColor: 'lightgrey', }}}><div className='buttontext'><Box sx={{display:'inline-flex',alignItems:'center'}}>Get in touch <ArrowForwardIcon sx={{ marginLeft:1,fontSize:20}}/></Box></div></Button>  
                        </Box>
                    </Box>
            </Box>   
            
        </Box>
    </div>
  )
}

export default Project
