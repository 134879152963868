import './App.css';
import About from './comp/About';
import HeroSection from "./comp/HeroSection";
import Whyus from "./comp/whyus";
import Industry from "./comp/Industry"
import Services from './comp/Services';
import Ourvision from './comp/Ourvision';
import Project from './comp/Project';
import Products from './comp/Products';
import Contactus from './comp/Contactus';
import Footer from './comp/Footer';
import Navbar from './comp/Navbar';
import ScrollToSectionButton from './comp/ScrollToSectionButton';

function App() {
  return (
    <div>
      
      <main>
      <Navbar />
        <HeroSection />
        <About />
        <Services/>
        <Whyus />
        <Industry /> 
        <Ourvision/>
        <Products/>
        <Contactus/>
        <Project/>
        <Footer/>
        <ScrollToSectionButton/>
      </main>
    </div>
  );
}

export default App;