import React, { Component } from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import AOS from 'aos';
import 'aos/dist/aos.css'; 
AOS.init();



class HeroSection extends Component {

  render() {

    return (
      <div id='HeroSection'>
        <Box sx={{ backgroundImage: 'linear-gradient(to bottom, white, #EEF4FB)', paddingBottom: { lg: 15, md: 13, sm: 10,xs:5 }, marginTop: { xs: -7,lg:-12,md:-10,sm:-8 }, marginBottom: { xs: 10, sm: 10, md: -5, lg: 3 }, filter: 'saturate(2)' }}>
          <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column', filter: 'saturate(4)' }}>
           
              <Box sx={{maxWidth:{lg:"50%",md:"60%",sm:'70%',xs:'96%'}}}>
              <div data-aos="fade-up"  data-aos-duration="1000" data-aos-once="true">
                  <Typography variant="h1" component="h1" sx={{ fontSize: { xs: 24, sm: 40, md: 45, lg: 50 }, fontFamily: 'Urbanist', textTransform: 'none', backgroundImage: 'linear-gradient(to right, #0053D6, #E862FE)', WebkitTextFillColor: 'transparent', WebkitBackgroundClip: 'text', textAlign: 'center', marginTop: { xs: 5, md: '7vw' }, fontWeight: "600" }}>
                    Elevating people's lives through Intelligent Automation
                  </Typography></div>
                  <div data-aos="fade-up"  data-aos-duration="1000" data-aos-delay='50' data-aos-once="true">
                  <Typography variant="h2" sx={{ fontSize: { xs: 15, sm: 18, md: 21, lg: 23 }, fontFamily: 'Urbanist', textTransform: 'none', color: '#E862FE', textAlign: 'center', marginTop: '1vw', fontWeight: "400" }}>
                    Your Personal AI-Powered Automation Expert
                  </Typography></div>
                  <div data-aos="fade-up"  data-aos-duration="1000" data-aos-delay='100'  data-aos-once="true">
                  <Typography variant="h3" sx={{ fontSize: { xs: 10, sm: 13, md: 14, lg: 15 }, fontFamily: 'Urbanist', textTransform: 'none', color: '#FF3BFF', textAlign: 'center', marginTop: '1vw', fontWeight: "400" }}>
                    ELEVATE EVERY ASPECT
                  </Typography></div>
              </Box>
          </Box>
        </Box>
      </div>
    );
  }
}

export default HeroSection;
