import { Box, Typography,} from '@mui/material';
import AI_driven_solutions from './images/AI-driven-solutions.webp';
import AOS from 'aos';
import 'aos/dist/aos.css'; 
AOS.init();


const About = () => {

  return (
    <div>
      <Box
        sx={{
          display: 'flex',
          maxWidth: '100%',
          marginLeft: '7%',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginRight: '2%',
          gap: '3%',
          flexDirection: { xs: 'column', md: 'row' },
          marginTop: { xs: -10 }
        }}
      >
        
        <Box
          sx={{
            maxWidth: { xs: '100%', sm: '85%', md: '55%', lg: '60%' },
            marginTop: {md: 15, sm: 5, xs: 3 },
            textAlign: 'justify',
            paddingRight: 1.5
          }}
        ><div data-aos="fade-up"  data-aos-duration="1000" data-aos-once="true">
            <Typography
              id="About"
              variant="h4"
              sx={{
                fontFamily: 'Montserrat',
                marginBottom: '1.5%',
                color: 'rgba(232, 98, 254, 1)',
                fontSize: { lg: 33, md: 32, sm: 30, xs: 25 },
                filter: 'saturate(3)'
              }}
            >
              About Us
            </Typography>
            <Typography
              sx={{
                fontFamily: 'Montserrat',
                lineHeight: {lg:1.6,md:1.6,sm:1.6,xs:1.5},
                textAlign: 'justify',
                fontSize: { lg: 19, md: 17, sm: 17, xs: 13 }
              }}
            >
              SeyarkAI represents a collectively dedicated enthusiastic professionals with diverse skills in software development, data science, and industry-specific domains. Driven by a shared commitment to excellence, the team aspires to lead in the realm of automation. Their focus lies in crafting innovative solutions that not only meet but exceed efficiency standards. With a pioneering spirit, Seyarkai endeavors to redefine the automation landscape, setting new benchmarks through cutting-edge solutions and contributing to transformative advancements in various industries.
            </Typography></div>
        </Box>
        
        <Box
          sx={{
            width: { xs: '100%', sm: '70%', md: '45%', lg: '40%' },
            marginTop: { xs: -6, md: 0, sm: -10, lg: 0 },
            display: 'flex',
            paddingLeft:{lg:0,md:0,sm:10,xs:7},
            alignItems: 'center'
          }}
        ><div data-aos="fade-up"  data-aos-duration="1000" data-aos-once="true">
          <img src={AI_driven_solutions} loading='lazy' alt="AI driven solutions and automation" style={{ width: '80%', height: 'auto', filter: 'saturate(1.1)', }} />
          </div>
        </Box>

      </Box>
    </div>
  );
};

export default About;
