import { Box, Button, Typography } from '@mui/material';
import React from 'react';
import ArrowForwardOutlinedIcon from '@mui/icons-material/ArrowForwardOutlined';

const ScrollUp = () => {
  const element = document.getElementById('Contact_Us');
  const offset = 100; // Adjust this value as needed
  const elementPosition = element.getBoundingClientRect().top + window.pageYOffset;
  const offsetPosition = elementPosition - offset;

  window.scrollTo({
    top: offsetPosition,
    behavior: 'smooth'
  });
};

const FooterBox = () => {
  return (
    <div>
      <Box
        sx={{
          margin: 'auto',
          width: { lg: '90%', md: '90%', sm: '80%', xs: '95%' },
          padding: { lg: 0, md: 1, sm: 5, xs: 5 },
          borderRadius: '20px',
          marginTop: { lg: 4, md: 4, sm: 6, xs: 4 },
          position: 'relative',
          background: 'linear-gradient(to right, #4E4BE4,#A358F2)',
          maskComposite: 'exclude',
          justifyContent:'center'
        }}
      >
        <Box
          sx={{
            margin: 'auto',
            width: '90%',
            display: { lg: 'flex', md: 'flex', sm: 'block', xs: 'block' }
          }}
        >
          <Box sx={{ width: {lg:'60%',md:'60%',sm:'90%', xs:'100%'}, color: 'white', margin: {lg:5,md:4,sm:'auto', xs:'auto'} }}>
            <Typography
              sx={{
                fontFamily: 'Inter',
                fontWeight: 800,
                fontSize: { lg: 30, md: 25, sm: 24, xs: 18 }
              }}
            >
              If you didn’t find the products you are interested in or have questions?
            </Typography>
          </Box>
          <Box sx={{ margin: {lg:5,md:4,sm:'auto', xs:'auto'}, width: {lg:'35%',md:'35%',sm:'90%', xs:'100%'}, justifyContent: 'left', textAlign: 'left' , marginTop: {lg:6,md:5,sm:1, xs:2},}}>
            <Typography
              sx={{
                color: 'white',
                fontSize: { lg: 15, md: 13, sm: 18, xs: 15 },
                fontFamily: 'Urbanist'
              }}
            >
              Just send us your contact details and we will contact you.
            </Typography>
            <Button
              variant='contained'
              onClick={ScrollUp}
              sx={{
                borderRadius: '20px',
                backgroundColor: 'black',
                fontFamily: 'Urbanist',
                fontWeight: 500,
                fontSize: { lg: 15, md: 14, sm: 15, xs: 12 },
                '&:hover': {
                  fontWeight: 600,
                  color: 'black',
                  backgroundColor: 'lightgrey'
                },
                marginTop: 1,
               
              }}
            >
              <div className='buttontext'>
                <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
                  Contact Us
                  <ArrowForwardOutlinedIcon sx={{  marginLeft: 1,fontSize:20}} />
                </Box>
              </div>
            </Button>
          </Box>
        </Box>
      </Box>
      <Typography variant='body2' align='center' sx={{ marginTop: 2, fontFamily: 'Urbanist', color: 'grey',marginBottom:-3.5 }}>
        &copy; 2024 — SeyarkAl
      </Typography>
    </div>
  );
};

export default FooterBox;