import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import { Drawer, List, ListItem, ListItemButton, ListItemText, Slide, CssBaseline } from '@mui/material';
import { Link as ScrollLink } from 'react-scroll';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Seyarkai_logo from './images/Seyarkai-logo.png';
import { debounce } from 'lodash';
import AOS from 'aos';
import 'aos/dist/aos.css';

const ScrollUp = () => {
  document.getElementById('Contact_Us').scrollIntoView({ behavior: 'smooth' });
};

const pages = [
  { text: 'About', to: 'About' },
  { text: 'Services', to: 'Services' },
  { text: 'Industries We Serve', to: 'Industries_We_Serve' },
  { text: 'Our Mission', to: 'Our_Mission' },
  { text: 'Why Us', to: 'Why_Us' },
  // { text: 'Testimonial', to: "Testimonial" },
  { text: 'Contact Us', to: 'Contact_Us' },
];

function HideOnScroll(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <Slide appear={false} direction="top" in={!trigger}>
      {children}
    </Slide>
  );
}

HideOnScroll.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

function Navbar(props) {
  const [OpenMenu, SetOpenMenu] = useState(false);

  useEffect(() => {
    AOS.init();
  }, []);

  const handleClick = debounce((pageTo) => {
    document.querySelector(`#${pageTo}`).scrollIntoView({ behavior: 'smooth' });
    SetOpenMenu(false);
  }, 100);

  return (
    <React.Fragment>
      <CssBaseline />
      <HideOnScroll {...props}>
        <AppBar
          id="Navbar"
          position="sticky"
          sx={{
            backgroundColor: 'rgba(255, 255, 255, 0.5)',
            backdropFilter: 'blur(10px)',
            color: '#0053D6',
            boxShadow: 'none',
            marginTop: 0.8,
            filter: 'saturate(3)',
          }}
        >
          <Container maxWidth="xl">
            <Toolbar disableGutters>
              <Box
                component="img"
                src={Seyarkai_logo}
                alt="Seyarkai Logo"
                loading="lazy"
                title="Seyarkai Logo"
                sx={{
                  display: 'block',
                  width: { xs: '100px', sm: '120px', md: '150px', lg: '170px' },
                  height: 'auto',
                }}
              />
              <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' }, justifyContent: 'flex-end' }}>
                <div className="navbar-menu-container">
                  <MenuIcon onClick={() => SetOpenMenu(true)} sx={{ cursor: 'pointer' }} />
                </div>
                <Drawer
                  open={OpenMenu}
                  onClose={() => SetOpenMenu(false)}
                  anchor="top"
                  ModalProps={{
                    BackdropProps: {
                      onClick: () => SetOpenMenu(false),
                    },
                  }}
                  PaperProps={{
                    sx: {
                      justifyContent: 'flex-end',
                      width: '50%',
                      height: { xs: 'auto' },
                      borderBottomLeftRadius: '20px',
                      borderBottomRightRadius: '20px',
                      backgroundColor: 'rgba(255, 255, 255, 0.5)',
                      backdropFilter: 'blur(10px)',
                      position: 'absolute',
                      left: "48%", 
                      top: 0, 
                    },
                  }}
                >
                  <Box
                    sx={{
                      paddingLeft: 0,
                      width: '100%',
                      backgroundColor: 'white',
                      overflow: 'hidden',
                    }}
                    role="presentation"
                  >
                    <List sx={{ marginLeft: 1,marginTop:2 }}>
                      {pages.map((page) => (
                        <ListItem key={page.text} disablePadding>
                          <ListItemButton
                            sx={{
                              color: '#0053D6',
                              textAlign: 'left',
                              position: 'relative',
                              '&:hover hr': {
                                backgroundColor: 'blue',
                              },
                            }}
                          >
                            <ScrollLink
                              to={page.to}
                              smooth={true}
                              duration={900}
                              offset={-70}
                              spy={true}
                              onClick={() => handleClick(page.to)}
                              data-aos="fade-down"
                              data-aos-duration="900"
                              data-aos-once="true"
                              data-aos-anchor-placement="top-bottom"
                            >
                              <ListItemText primary={page.text} />
                            </ScrollLink>
                            
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                </Drawer>
              </Box>
              <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' }, justifyContent: 'space-evenly', marginLeft: 3 }}>
                {pages.map((page) => (
                  <Button key={page.text} sx={{ mx: 1, my: 1, display: 'block', color: '#0053D6', fontSize: '1.1vw' }}>
                    <ScrollLink to={page.to} smooth={true} duration={1000} offset={-70} spy={true}>
                      {page.text}
                    </ScrollLink>
                  </Button>
                ))}
              </Box>
              <Box
                sx={{
                  flexGrow: 1,
                  display: { xs: 'none', md: 'flex' },
                  justifyContent: 'flex-end',
                  fontSize: '0.5vw',
                  fontWeight: 500,
                }}
              >
                <Fab
                  variant="extended"
                  sx={{
                    background: '#0053D6',
                    color: 'white',
                    fontSize: '0.9vw',
                    boxShadow: 'none',
                    '&:hover': {
                      background: 'light-grey',
                      color: '#0053D6',
                      borderColor: '#0053D6',
                      fontWeight: 500,
                    },
                  }}
                  onClick={ScrollUp}
                >
                  Talk to an expert
                </Fab>
              </Box>
            </Toolbar>
          </Container>
        </AppBar>
      </HideOnScroll>
      <Toolbar />
    </React.Fragment>
  );
}

Navbar.propTypes = {
  window: PropTypes.func,
};

export default Navbar;
