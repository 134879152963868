import React, { useState } from 'react';
import { Box, Button, Grid, TextField, Typography, Link, Alert } from '@mui/material';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import EmailIcon from '@mui/icons-material/Email';
// import LocationOnIcon from '@mui/icons-material/LocationOn';
import YouTubeIcon from '@mui/icons-material/YouTube';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import InstagramIcon from '@mui/icons-material/Instagram';
import Contactus_bg from "../comp/images/Contactus_bg.webp";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { db, addDoc,collection } from "./firebase-connection/firebase";


AOS.init();

const Contactus = () => {
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    message: ''
  });

  const [alert, setAlert] = useState({
    show: false,
    message: '',
    severity: 'error'
  });

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const validateEmail = (email) => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return re.test(email);
  };
  
  
  const handleSubmit = async () => {
    const { firstName, lastName, email, phone, message } = formData;

    if (!firstName || !lastName || !email || !phone || !message) {
      setAlert({
        show: true,
        message: 'Please fill out all fields.',
        severity: 'error'
      });
      return;
    }

    if (!validateEmail(email)) {
      setAlert({
        show: true,
        message: 'Please enter a valid email address.',
        severity: 'error'
      });
      return;
    }

    // Clear the alert and proceed with form submission
    setAlert({ show: false, message: '', severity: 'error' });

    try {
      // Add a new document with form data to Firestore
      await addDoc(collection(db, 'Landing_V1_client_details'), {
        firstName,
        lastName,
        email,
        phone,
        message,
        timestamp: new Date()
      });
      setAlert({
        show: true,
        message: 'Message sent successfully!',
        severity: 'success'
      });
    } catch (error) {
      setAlert({
        show: true,
        message: `Error sending message: ${error.message}`,
        severity: 'error'
      });
    }
  };
  return (
    <div id='Contact_Us'>
      <Box
        sx={{
          marginTop: { lg: 8, md: 6, sm: 6, xs: 2 },
          marginBottom: { lg: 8, md: 6, sm: 4, xs: 2 },
          marginRight: { lg: 9, md: 6, sm: 5, xs: 1 },
          marginLeft: { lg: 9, md: 6, sm: 5, xs: 1 },
          paddingTop: { lg: 3, md: 2, sm: 2, xs: 1 },
          paddingBottom: { lg: 3, md: 2, sm: 2, xs: 1 },
          backgroundColor: '#EEF4FB',
          borderRadius: '16px',
        }}>
        <Box
          sx={{
            margin: 'auto',
            width: { lg: '90%', md: '90%', sm: '90%', xs: '95%' },
            marginTop: 1,
            display: { lg: 'flex', md: 'flex', sm: 'flex' },
            flexDirection: { lg: 'row', md: 'row', sm: 'column', xs: 'column' },
          }}>
          <Box 
                
                sx={{backgroundColor: '#0053D6',
                flex:{md:0.5},
                borderRadius: '16px',
                padding: { lg: 4 ,md:4,sm:4,xs:3},
                marginRight:{lg:17,md:10},
                maxWidth: { lg: '35%',md:'100%',sm:'100%' ,xs:'100%'},
                backgroundImage: `url(${Contactus_bg})`,
                backgroundPosition: 'bottom right',
                backgroundSize:{lg:'70%',md:'75%',sm:'45%',xs:'55%'},
                backgroundRepeat:'no-repeat',
                position: 'relative',
                top: 0,
                right: 0,
                }}>
                        
                <Typography data-aos="fade-up"  data-aos-duration="1000" data-aos-once="true"  data-aos-anchor-placement="top-bottom"   sx={{fontFamily:'Urbanist',color:'white',fontSize:{lg:23,md:20,sm:26,xs:24},textAlign:{lg:'left',md:'left',sm:'left',xs:'center'}}}>Contact Information</Typography>
                <Typography  data-aos="fade-up"  data-aos-duration="1000" data-aos-once="true"  data-aos-anchor-placement="top-bottom"   sx={{fontFamily:'Urbanist',color:'white',fontSize:{lg:13,md:13,sm:17,xs:15},textAlign:{lg:'left',md:'left',sm:'left',xs:'center'}}}>Say something to start a chat!</Typography>
                    <Box sx={{marginTop:{lg:10,md:7,sm:4,xs:3},    
                    display: 'flex',
                    flexDirection: {lg:'column',md:'column',sm:'column',xs:'column'},
                    marginRight:{lg:10,md:5,sm:2,xs:3},
                    justifyContent:{sm:'space-between'}
                    }}>
                        <Box>
                        <Box
                         data-aos="fade-up"  data-aos-duration="1000" data-aos-once="true"  data-aos-anchor-placement="top-bottom" 
                        sx={{
                            display: "flex",
                            flexDirection: {lg:'row',md:'row',sm:'row',xs:'row'},
                            alignItems: "center",
                            gap:{lg:2,md:1,sm:1,xs:1},
                            marginBottom:{lg:5,md:4,sm:2,xs:2}
                        }}
                        >
                            <LocalPhoneIcon  sx={{color:'white',fontSize:{lg:25,md:21,sm:22,xs:20}}} />
                            <Typography sx={{ fontSize: {lg:16,md:15,sm:17,xs:14},
                            fontFamily: "'Urbanist', sans-serif",
                            color: "#FFFFFF",
                            fontWeight: "300",
                            alignItems: "center", }}>+91 93636 07947</Typography>
                        </Box>

                        <Box
                         data-aos="fade-up"  data-aos-duration="1000" data-aos-once="true"  data-aos-anchor-placement="top-bottom" 
                        sx={{
                            display: "flex",
                            flexDirection: {lg:'row',md:'row',sm:'row',xs:'row'},
                            alignItems: "center",
                            gap:{lg:2,md:1,sm:1,xs:1},
                            marginBottom:{lg:5,md:4,sm:2,xs:2}
                        }}
                        >
                            <EmailIcon  sx={{color:'white',fontSize:{lg:25,md:21,sm:22,xs:20}}} />
                            <Typography sx={{ fontSize: {lg:16,md:15,sm:17,xs:14},
                            fontFamily: "'Urbanist', sans-serif",
                            textTransform: "none",
                            color: "#FFFFFF",
                            fontWeight: "300",
                            alignItems: "center", }}>contactus@seyarkai.tech</Typography>
                        </Box>
                        </Box>
                        
                        <Box
                            data-aos="fade-up"  data-aos-duration="1000" data-aos-once="true"  data-aos-anchor-placement="top-bottom"       
                            sx={{
                                display: "flex",
                                flexDirection: {lg:'row',md:'row',sm:'row',xs:'row'},
                                alignItems: "center",
                                gap:{lg:2,md:1,sm:1,xs:1},
                            
                            }}
                            >
                                {/* <LocationOnIcon  sx={{color:'white',fontSize:{lg:25,md:21,sm:22,xs:20}}}/>
                                <Typography sx={{ fontSize: {lg:16,md:15,sm:17,xs:14},
                                fontFamily: "'Urbanist', sans-serif",
                                textTransform: "none",
                                color: "#FFFFFF",
                                fontWeight: "300",
                                alignItems: "center", }}>300 S Main S treet, Suite 212 Holly Springs, NC 27540</Typography> */}
                        </Box>
                    <Box>
                    </Box>   
                    </Box>
                    <Box sx={{marginTop:{lg:12,md:13,sm:8,xs:6},display:'flex',alignItems:'center',gap:2,}}>
                    <Link data-aos="zoom-in"  data-aos-duration='1000' data-aos-delay='200' data-aos-once='true'  data-aos-anchor-placement="top-bottom"  href='https://www.youtube.com/@SeyarkAI' target="_blank" rel="noopener noreferrer">
                        <YouTubeIcon    sx={{backgroundColor:'white',color:'rgb(141, 141, 141)',fontSize:{lg:'2.5vw',md:'3vw',sm:'4.5vw',xs:'9vw'},padding:{lg:1.1,md:0.9,sm:1,xs:1},borderRadius:'20px',border:'none',cursor:'pointer','&:hover': {color:'white',border:'none',backgroundColor:'red'},transition: 'color 0.3s ease'}} />
                    </Link>
                    <Link  data-aos="zoom-in" data-aos-duration='1000' data-aos-delay='250'  data-aos-once='true'  data-aos-anchor-placement="top-bottom"  href='https://www.linkedin.com/company/seyarkai/' target="_blank" rel="noopener noreferrer">
                    <LinkedInIcon     sx={{backgroundColor:'white',color:'rgb(141, 141, 141)',fontSize:{lg:'2.5vw',md:'3vw',sm:'4.5vw',xs:'9vw'},padding:{lg:1.1,md:0.9,sm:1,xs:1},borderRadius:'20px',border:'none',cursor:'pointer','&:hover': {color:'#4267B2',border:'none',backgroundColor:'white'},transition: 'color 0.3s ease'}} />
                    </Link>
                    <Link  data-aos="zoom-in" data-aos-duration="1000"  data-aos-delay='300'  data-aos-once='true'  data-aos-anchor-placement="top-bottom" href='https://www.instagram.com/seyarkai.tech?igsh=MXN5am95YXJsNHVycg==' target="_blank" rel="noopener noreferrer">
                    <InstagramIcon    sx={{backgroundColor:'white',color:'rgb(141, 141, 141)',fontSize:{lg:'2.5vw',md:'3vw',sm:'4.5vw',xs:'9vw'},padding:{lg:1.1,md:0.9,sm:1,xs:1},borderRadius:'20px',border:'none',cursor:'pointer','&:hover': {backgroundImage: 'linear-gradient(to right, #833AB4, #FD1D1D)',color:'white'},transition: 'color 0.3s ease'}} />
                    </Link>
                    </Box>
                    
                </Box>

                <Box sx={{marginTop:{lg:3,md:5,sm:7,xs:5},flex:{md:0.5},marginLeft:{lg:0,md:0,sm:4,xs:3},marginRight:{lg:0,md:0,sm:4,xs:5}}}>
                        {alert.show && (
                        <Alert severity={alert.severity} sx={{ marginTop: 0 }}>
                        {alert.message}
                        </Alert>
                        )}
                      <Grid container spacing={4} sx={{marginTop:{lg:0.4,md:1,sm:1,xs:1}}}>
                      
                        <Grid item lg={6} md={6} sm={6} xs={12} sx={{marginTop:{lg:2,md:1,sm:2,xs:1}}}>
                       
                            <TextField
                                data-aos='fade-up' data-aos-duration='2000' data-aos-once='true'  data-aos-anchor-placement="top-bottom"
                                id="firstName"
                                label="First Name"
                                type="text"
                                variant="standard"
                                value={formData.firstName}
                                onChange={handleInputChange}
                                InputLabelProps={{ shrink: true, }}
                                sx={{
                                width:'100%',
                                paddingTop:{lg:1,md:1,sm:1,xs:1},
                                '& .MuiInputLabel-root': { color: '#616161 ', fontFamily: 'Urbanist',fontSize:{lg:19,md:19,sm:19,xs:18}, }, 
                                '& .MuiInputBase-input': {  fontFamily: 'Urbanist',},
                                '& .MuiInput-underline:after': { borderBottomColor: 'black' }, 
                                '&:focus-within': { 
                                    '& .MuiInputLabel-root': { color: 'black',  fontFamily: 'Urbanist',fontSize:{lg:20,md:20,sm:20,xs:19} }, 
                                    '& .MuiInputBase-input': {  fontFamily: 'Urbanist', },
                                    '& .MuiInput-underline:after': { borderBottomColor: 'black' },
                                },
                                }}
                            />
                            </Grid>
                           
                            <Grid item lg={6} md={6} sm={6} xs={12} sx={{marginTop:{lg:2,md:1,sm:2,xs:1}}}>
                            
                            <TextField
                                data-aos='fade-up' data-aos-duration='2000' data-aos-once='true'  data-aos-anchor-placement="top-bottom"
                                id="lastName"
                                label="Last Name"
                                type="text"
                                variant="standard"
                                value={formData.lastName}
                  onChange={handleInputChange}
                                InputLabelProps={{ shrink: true }}
                                sx={{
                                width:'100%',
                                paddingTop:{lg:1,md:1,sm:1,xs:1},
                                '& .MuiInputLabel-root': { color: '#616161 ', fontFamily: 'Urbanist',fontSize:{lg:19,md:19,sm:19,xs:18}, }, 
                                '& .MuiInputBase-input': {  fontFamily: 'Urbanist',},
                                '& .MuiInput-underline:after': { borderBottomColor: 'black' }, 
                                '&:focus-within': { 
                                    '& .MuiInputLabel-root': { color: 'black',  fontFamily: 'Urbanist',fontSize:{lg:20,md:20,sm:20,xs:19}  }, 
                                    '& .MuiInputBase-input': {  fontFamily: 'Urbanist', },
                                    '& .MuiInput-underline:after': { borderBottomColor: 'black' },
                                },
                                }}
                            />
                            </Grid>
                    
                            <Grid item lg={6} md={6} sm={6} xs={12} sx={{marginTop:{lg:2,md:1,sm:2,xs:1}}}>
                           
                            <TextField
                                data-aos='fade-up' data-aos-duration='2000' data-aos-once='true'  data-aos-anchor-placement="top-bottom"
                                id="email"
                                label="Email"
                                type="email"
                                variant="standard"
                                value={formData.email}
                  onChange={handleInputChange}
                                InputLabelProps={{ shrink: true }}
                                sx={{
                                width:'100%',
                                paddingTop:{lg:1,md:1,sm:1,xs:1},
                                '& .MuiInputLabel-root': { color: '#616161 ', fontFamily: 'Urbanist',fontSize:{lg:19,md:19,sm:19,xs:18}, }, 
                                '& .MuiInputBase-input': {  fontFamily: 'Urbanist',},
                                '& .MuiInput-underline:after': { borderBottomColor: 'black' }, 
                                '&:focus-within': { 
                                    '& .MuiInputLabel-root': { color: 'black',  fontFamily: 'Urbanist',fontSize:{lg:20,md:20,sm:20,xs:19}  }, 
                                    '& .MuiInputBase-input': {  fontFamily: 'Urbanist', },
                                    '& .MuiInput-underline:after': { borderBottomColor: 'black' },  
                                },
                                }}
                            />
                            </Grid>

                            <Grid item lg={6} md={6} sm={6} xs={12} sx={{marginTop:{lg:2,md:1,sm:2,xs:1}}}>
                            
                            <TextField
                                data-aos='fade-up' data-aos-duration='2000' data-aos-once='true'  data-aos-anchor-placement="top-bottom"
                                id="phone"
                                label="Phone Number"
                                type="text"
                                variant="standard"
                                defaultValue='+91  '
                                value={formData.phone}
                                onChange={handleInputChange}
                                InputLabelProps={{ shrink: true }}
                                sx={{
                                width:'100%',
                                paddingTop:{lg:1,md:1,sm:1,xs:2},
                                '& .MuiInputLabel-root': { color: '#616161 ', fontFamily: 'Urbanist',fontSize:{lg:19,md:19,sm:19,xs:18}, }, 
                                '& .MuiInputBase-input': {  fontFamily: 'Urbanist',},
                                '& .MuiInput-underline:after': { borderBottomColor: 'black' }, 
                                '&:focus-within': { 
                                    '& .MuiInputLabel-root': { color: 'black',  fontFamily: 'Urbanist',fontSize:{lg:20,md:20,sm:20,xs:19}  }, 
                                    '& .MuiInputBase-input': {  fontFamily: 'Urbanist', },
                                    '& .MuiInput-underline:after': { borderBottomColor: 'black' },
                                },
                                }}
                            />
                            </Grid>
                            <Grid item lg={12} md={12} sm={12} xs={12} sx={{marginTop:{lg:2,md:1,sm:2,xs:1}}}>
                          
                            <TextField
                                data-aos='fade-up' data-aos-duration='2000' data-aos-once='true'  data-aos-anchor-placement="top-bottom"
                                id="message"
                                label="Message"
                                type="text"
                                variant="standard"
                                value={formData.message}
                                onChange={handleInputChange}
                                placeholder='Write your message..'
                                InputLabelProps={{ shrink: true }}
                                sx={{
                                width:'100%',
                                paddingTop:{lg:1,md:1,sm:1,xs:1},
                                '& .MuiInputLabel-root': { color: '#616161 ', fontFamily: 'Urbanist',fontSize:{lg:19,md:19,sm:19,xs:18}, }, 
                                '& .MuiInputBase-input': {  fontFamily: 'Urbanist',},
                                '& .MuiInput-underline:after': { borderBottomColor: 'black' }, 
                                '&:focus-within': { 
                                    '& .MuiInputLabel-root': { color: 'black',  fontFamily: 'Urbanist',fontSize:{lg:20,md:20,sm:20,xs:19}  }, 
                                    '& .MuiInputBase-input': {  fontFamily: 'Urbanist', },
                                    '& .MuiInput-underline:after': { borderBottomColor: 'black' },
                                },
                                }}
                            />
                            </Grid>
                      </Grid>  
                      <Box sx={{ display: 'flex', justifyContent: {lg:'flex-end',md:'flex-end',sm:'center',xs:'center'}}}>
                      
                        <Button
                         data-aos='zoom-in' data-aos-duration='2000' data-aos-once='true' data-aos-anchor-placement="top-bottom"
                         variant='contained' onClick={handleSubmit} sx={{
                            marginTop: { lg: 10, md: 12, sm: 8, xs:7 },
                            marginBottom:{lg:0,md:0,sm:4,xs:4},
                            fontFamily: 'Urbanist',
                            borderRadius: '20px',
                            backgroundImage: 'linear-gradient(to right, #200dce, #570ad6, #7b09dd, #9a0ce4, #b612eb)',
                            textTransform: 'none'
                        }}>
                            Send Message
                        </Button>
                      </Box>
                </Box>         
        </Box>
      </Box>
    </div>
  );
};

export default Contactus;
